import './old/services'
import './landing'
import './landing.sass'

const tabsInit = () => {
  const buttons = document.querySelectorAll('[data-tab-id]')
  buttons.forEach(b => {
    b.addEventListener('click', (event) => {
      const tabBodies = document.querySelectorAll('.agenda-box__tab-body ul')
      tabBodies.forEach((body) => body.classList.add('hidden'));
      buttons.forEach(button => button.classList.remove('active'));
      document.getElementById(event.target.dataset.tabId).classList.remove('hidden');
      event.target.classList.add('active');
    })
  })
}

const scrollToSectionInit = () => {
  const menuEl = document.querySelectorAll('[data-scroll]')
  const hamburgerBtn = document.querySelector('.mobile-nav-btn')
  const mobileNavItemClass = 'mobile-nav-item'
  menuEl.forEach(el => {
    el.addEventListener('click', (event) => {
      document.querySelector(`.${event.target.dataset.scroll}`).scrollIntoView({ behavior: 'smooth' })
      if ([...el.classList].includes(mobileNavItemClass)) {
        toggleMobileMenu(hamburgerBtn)
      }
    })
  })
}


const toggleMobileMenu = (btnEl) => {
const mobileNav = document.querySelector('.mobile-nav')
  const ACTIVE_CLASS = 'active'
  btnEl.classList.toggle(ACTIVE_CLASS)
  mobileNav.classList.toggle(ACTIVE_CLASS)
  document.body.classList.toggle(ACTIVE_CLASS)
}

const mobileMenuInit =  () => {
  const hamburgerBtn = document.querySelector('.mobile-nav-btn')
  hamburgerBtn.addEventListener('click', (event) => toggleMobileMenu(event.target))
}


document.addEventListener('DOMContentLoaded', () => {
  tabsInit()
  scrollToSectionInit()
  mobileMenuInit()
})
