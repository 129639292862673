$(document).on('ready turbolinks:load ajax:success', function() {
  const $services = $('.service');

  $services.each(function () {
    const $service = $(this);
    const activeServiceClass = 'service--active';
    const $dropButton = $service.find('.service__drop-button');
    const activeButtonClass = 'service__drop-button--active';
    const $body = $service.find('.service__body-wrapper');

    $dropButton.off('click');
    $dropButton.on('click', function () {
      if ($dropButton.hasClass(activeButtonClass)) {
        $dropButton.removeClass(activeButtonClass);
        $body.slideUp(150, function () {
          $service.removeClass(activeServiceClass);
        });
        return;
      }
      $dropButton.addClass(activeButtonClass);
      $service.addClass(activeServiceClass);
      $body.slideDown(150);
    });
  });
});

